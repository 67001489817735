import React from "react";
import { motion } from "framer-motion";

import Breadcrumbs from "../../../components/breadcrumbs/breadcrumbs.component";

import commonStyles from "../common.module.scss";
import styles from "./festivals.module.scss";
import banner from "../../../images/events/girl-with-clown-costume-and-candy.png";

import junior from "../../../images/events/icons/junior group.svg";
import mixed from "../../../images/events/icons/mixed group.svg";
import senior from "../../../images/events/icons/senior group.svg";
import schools from "../../../images/events/icons/shcool teatres.svg";
import udo from "../../../images/events/icons/theaters based on udo.svg";
import colledge from "../../../images/events/icons/college-based theaters.svg";
import melpomene from "../../../images/events/icons/school melpomene.svg";
import waist from "../../../images/events/icons/supportive waist.svg";

const FestivalsPage = () => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 0.2, duration: 1 }}
        >
            <Breadcrumbs
                items={[
                    {
                        title: "Главная",
                        url: "/",
                    },
                    {
                        title: "Фестиваль «Живая сцена»",
                        url: "",
                    },
                ]}
            />
            <section
                className={[commonStyles.wrap, commonStyles.wrap_shadow, styles.banner].join(" ")}
                style={{ backgroundImage: `url(${banner})` }}
            >
                <h2 className={[commonStyles.title, styles.topTitle].join(" ")}>
                    Московский театральный <br />
                    фестиваль-конкурс <br />
                    «Живая сцена»
                </h2>
            </section>
            <section className={[commonStyles.wrap, commonStyles.wrap_bubble].join(" ")}>
                <h2 className={[commonStyles.title, commonStyles.title_underline].join(" ")}>
                    кто может стать участником?
                </h2>
                <p className={styles.description}>
                    Участниками Фестиваля-конкурса являются детские и молодежные театры на базе образовательных
                    организаций города Москвы, входящие в «Содружество Школьных театров города Москвы»
                </p>
                <ul className={styles.cardDeck}>
                    <li className={styles.card_with_icons}>
                        <img className={styles.cardIcon} src={schools} alt='Иконка школьные театры' />
                        <h3 className={styles.cardTitle_pr}>Школьные театры</h3>
                    </li>
                    <li className={styles.card_with_icons}>
                        <img className={styles.cardIcon} src={colledge} alt='Иконка театры на базе колледжа' />
                        <h3 className={styles.cardTitle_pr}>театры на базе колледжа</h3>
                    </li>
                    <li className={styles.card_with_icons}>
                        <img className={styles.cardIcon} src={udo} alt='Иконка театры на базе УДО' />
                        <h3 className={styles.cardTitle_pr}>театры на базе УДО</h3>
                    </li>
                </ul>
            </section>
            <section className={[commonStyles.wrap, commonStyles.wrap_shadow, styles.blueSection].join(" ")}>
                <h2 className={[commonStyles.title, commonStyles.title_underline].join(" ")}>
                    в каких направлениях может принять участие театр?
                </h2>
                <ul className={styles.cardDeck}>
                    <li className={styles.card_with_fest_icons}>
                        <img className={styles.cardIcon_fest} src={waist} alt='Иконка Благосклонная Талия' />
                        <div>
                            <h3 className={styles.cardTitle}>Благосклонная Талия</h3>
                            <p className={styles.cardText}>
                                <span>Ноябрь 2023 г. – Апрель 2024 г.</span>
                                Определяет наиболее активные театральные образовательные пространства участников
                            </p>
                        </div>
                    </li>
                    <li className={styles.card_with_fest_icons}>
                        <img className={styles.cardIcon_fest} src={melpomene} alt='Иконка Школьная Мельпомена' />
                        <div>
                            <h3 className={styles.cardTitle}>Школьная Мельпомена</h3>
                            <p className={styles.cardText}>
                                <span>Ноябрь 2023 г. – Апрель 2024 г.</span>
                                Включает открытый показ спектаклей финалистов на профессиональной сцене
                            </p>
                        </div>
                    </li>
                </ul>
            </section>
            <section className={[commonStyles.wrap, commonStyles.wrap_bubble].join(" ")}>
                <h2 className={[commonStyles.title, commonStyles.title_underline].join(" ")}>благосклонная талия</h2>
                <div className={styles.cardDeck}>
                    <div className={styles.card_br_ac}>
                        <h3 className={styles.cardTitle_bg_ac}>один этап</h3>
                        <p className={styles.cardText}>
                            Оценивается активная деятельность школьного театра внутри школы, во внешней среде, показы спектаклей, фотографии, видео, афиши, и многое другое. Стань театром-лидером фестиваля-конкурса "Живая сцена".
                        </p>
                    </div>
                    <div className={styles.card_br_ac}>
                        <h3 className={styles.cardTitle_bg_ac}>размещение заявки</h3>
                        <p className={styles.cardText}>
                            Заявка и конкурсные материалы размещаются на портале Содружества
                            <span>с 20.11.2023 по 20.04.2024</span>
                        </p>
                    </div>
                    <div className={styles.card_br_ac}>
                        <h3 className={styles.cardTitle_bg_ac}>Подведение итогов и награждение</h3>
                        <p className={styles.bigText}>с 20.04.2024 по 30.04.2024</p>
                    </div>
                </div>
            </section>
            <section className={[commonStyles.wrap, commonStyles.wrap_bubble].join(" ")}>
                <h2 className={[commonStyles.title, commonStyles.title_underline].join(" ")}>Школьная Мельпомена</h2>
                <div className={styles.cardDeck}>
                    <div className={styles.card_br_ac}>
                        <h3 className={styles.cardTitle_bg_pr}>4 этапа</h3>
                        <ul className={styles.cardList}>
                            <li>
                                <span>1 этап</span>
                                размещение заявок и конкурсных материалов на портале
                                <span>с 20.11.2023 по 15.01.2024</span>
                            </li>
                            <li>
                                <span>2 этап (заочный)</span>
                                проведение заочного отбора спектаклей участников Фестиваля
                                <span>с 15.01.2024 по 02.02.2024</span>
                            </li>
                            <li>
                                <span>3 этап</span>
                                показ спектаклей на сцене образовательной площадки
                                <span>с 05.02.2024 по 10.03.2024</span>
                            </li>
                            <li>
                                <span>4 этап</span>
                                показ спектаклей участников, прошедших в финал конкурса
                                <span>с 20.03.2024 по 20.04.2024</span>
                            </li>
                            <li>
                                <span>Церемония награждения фестиваля Апрель 2024</span>
                            </li>
                        </ul>
                    </div>
                    <div className={styles.card_br_ac}>
                        <h3 className={styles.cardTitle_bg_pr}>Размещение заявки</h3>
                        <p className={styles.cardText}>
                            Можно заявить только один спектакль Школьного театра от одной образовательной организации
                            <br/>
                            <br/>
                            Заявка и конкурсные материалы размещаются в личном кабинете на Портале Содружества
                            <span>до 15.01.2024</span>
                        </p>
                    </div>
                    <div className={styles.card_br_ac}>
                        <h3 className={styles.cardTitle_bg_pr}>Две номинации</h3>
                        <p className={styles.bigText}>«Драматический спектакль» </p>
                        <p className={styles.bigText}>«Музыкальный спектакль»</p>
                    </div>
                </div>
            </section>
            <section className={[commonStyles.wrap, commonStyles.wrap_shadow, styles.blueSection].join(" ")}>
                <h2 className={[commonStyles.title, commonStyles.title_underline, styles.title].join(" ")}>
                    Возрастные группы* <span>Актуально для направления “Школьная Мельпомена”</span>
                </h2>
                <ul className={styles.cardDeck}>
                    <li className={styles.card_with_icons}>
                        <img className={styles.cardIcon} src={junior} alt='Иконка Младшая Обучающиеся' />
                        <h3 className={styles.cardTitle_ws}>
                            Младшая
                            <span>обучающиеся c 1 по 5 класс</span>
                        </h3>
                    </li>
                    <li className={styles.card_with_icons}>
                        <img className={styles.cardIcon} src={senior} alt='Иконка Старшая Обучающиеся' />
                        <h3 className={styles.cardTitle_ws}>
                            Старшая
                            <span>обучающиеся с 6 по 11 класс и Студенты колледжей</span>
                        </h3>
                    </li>
                    <li className={styles.card_with_icons}>
                        <img className={styles.cardIcon} src={mixed} alt='Иконка Смешанная' />
                        <h3 className={styles.cardTitle_ws}>
                            Смешанная <span>обучающиеся из разных возрастных групп</span>
                        </h3>
                    </li>
                </ul>
            </section>
            <section className={[commonStyles.wrap, commonStyles.wrap_shadow].join(" ")}>
                <h2 className={[commonStyles.title, commonStyles.title_underline].join(" ")}>
                    Хочешь узнать подробнее о фестивале?
                </h2>
                <p className={styles.feedback}>
                    Подробную информацию о проведении Фестиваля-конкурса <span>«Живая сцена»</span> вы можете найти в{" "}
                    <a
                        href={process.env.REACT_APP_BASE_URL + "docs/festivals/Положение Живая сцена.pdf"}
                        target={"_blank"}
                        rel='noopener nofollow noreferrer'
                    >
                        положении
                    </a>{" "}
                    или написать нам на почту{" "}
                    <a href='mailto:sodruzhestvotheatre@edu.mos.ru' rel='noopener nofollow noreferrer'>
                        sodruzhestvo&shy;theatre@edu.mos.ru
                    </a>
                </p>
            </section>
        </motion.div>
    );
};

export default FestivalsPage;
