import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import styles from "./banner.module.scss";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import commonStyles from "../../pages/public/common.module.scss";

const BannerSlider = ({
    items,
    index = 0,
    infiniteLoop = true,
    showArrows = true,
    showStatus = false,
    showThumbs = false,
    autoPlay = false,
    interval = 10000,
    transitionTime = 0,
    swipe = true,
}) => {
    return (
        <>
            <section className={styles.section}>
                <div className={styles.wrap}>
                    <Splide
                        className='my-splide'
                        options={{
                            type: "loop",
                            arrows: false,
                            perPage: 1,
                            perMove: 1,
                            gap: "1.5em",
                            rewind: true,
                            autoplay: true,
                            mediaQuery: "min",
                            pauseOnHover: true,
                        }}
                    >
                        {items.map((item, index) => (
                            <SplideSlide data-splide-interval='5000' key={index}>
                                <img
                                    key={index}
                                    className={styles.img}
                                    src={item.url}
                                    alt={item.url}
                                    onClick={() => {
                                        if(item.link) {
                                            window.open(item.link, "_blank");
                                        }
                                    }}
                                />
                            </SplideSlide>
                        ))}
                    </Splide>
                </div>
            </section>
        </>
    );
};

export default BannerSlider;
