import React from "react";
import FieldInput from "../field/field.input.component";
import Button from "../button/button.component";
import MultiSelect from "../multi_select/multi_select.component";

const Score = ({
                   score = 0,
                   place,
                   season = 0,
                   minScore = 0,
                   maxScore = 10000,
                   withSeasonSelect = false,
                   control,
                   getValues,
                   register,
                   saveScore = (place, score, season) => {
                   },
                   scoreEdit = false,
                   marginBottom = "1em"
               }) => {
    const [edit, setEdit] = React.useState(false);
    const [currentScore, setCurrentScore] = React.useState(getValues(place) ? getValues(place) : score);

    const getSeasonText = (season) => {
        switch (season) {
            case 1:
                return "Сезон 1 (до 31.07.2023)";
            case 2:
                return "Сезон 2 (01.08.2023 - 20.04.2024)";
            default:
                return "";
        }
    };

    return <>
        {
            scoreEdit
                ?
                <>
                    {
                        edit
                            ?
                            (<div style={{display: "flex", gap: "0.675em", marginBottom: marginBottom}}>
                                {
                                    withSeasonSelect
                                    &&
                                    <div className='form__multy-block'>
                                        <MultiSelect
                                            required={true}
                                            control={control}
                                            isMulti={false}
                                            name={"season_select_" + place}
                                            closeMenuOnSelect={true}
                                            options={[
                                                {
                                                    label: getSeasonText(1),
                                                    value: 1,
                                                },
                                                {
                                                    label: getSeasonText(2),
                                                    value: 2,
                                                },
                                            ]}
                                            values={{
                                                label: getSeasonText(season),
                                                value: season,
                                            }}
                                        />
                                    </div>
                                }
                                <FieldInput
                                    size='small'
                                    type='number'
                                    min={minScore}
                                    max={maxScore}
                                    style={{width: '4em'}}
                                    {...register(place, {
                                        value: currentScore
                                    })}
                                    onInput={(e) => {
                                        e.target.value =  Math.max(Number(minScore), Math.min(Number(maxScore), Number(e.target.value)));
                                    }}
                                />
                                <Button
                                    type='button'
                                    size='small'
                                    theme='success'
                                    isIconBtn='true'
                                    iconClass='mdi mdi-check'
                                    aria-label='Сохранить баллы'
                                    onClick={() => {
                                        saveScore(place, parseInt(getValues(place)), withSeasonSelect ? parseInt(getValues("season_select_" + place).value) : 0);
                                        setCurrentScore(parseInt(getValues(place)));
                                        setEdit(!edit);
                                    }}
                                />
                                <Button
                                    type='button'
                                    size='small'
                                    theme='text'
                                    isIconBtn='true'
                                    iconClass='mdi mdi-close'
                                    aria-label='Отмена'
                                    onClick={() => {
                                        setEdit(!edit);
                                    }}
                                />
                            </div>)
                            :
                            (<>
                                <Button
                                    theme='info_outline'
                                    type='button'
                                    size='small'
                                    iconClass='mdi mdi-pencil'
                                    style={{marginBottom: marginBottom}}
                                    text={`${currentScore} ` + window.global.declOfNum(currentScore, ['балл', 'балла', 'баллов'])}
                                    onClick={() => {
                                        setEdit(!edit);
                                    }}
                                />
                                <span>&nbsp;&nbsp;{getSeasonText(season)}</span>
                            </>)
                    }
                </>
                :
                <>
                    <Button
                        theme='info_outline'
                        type='button'
                        size='small'
                        text={`${currentScore} ` + window.global.declOfNum(currentScore, ['балл', 'балла', 'баллов'])}
                        style={{pointerEvents: "none", marginBottom: marginBottom}}
                    />
                    <span>&nbsp;&nbsp;{getSeasonText(season)}</span>
                </>
        }
    </>
}

export default Score;