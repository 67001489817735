import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {YMaps, Map, Placemark} from "react-yandex-maps";
import moment from "moment";
import createDOMPurify from "dompurify";
import {motion} from "framer-motion";

import useTheatresStore from "../../../store/public/theatresStore";
import useSchoolStore from "../../../store/public/schoolsStore";
import useSpectaclesStore from "../../../store/public/spectaclesStore";

import ImageGallery from "../../../components/image_gallery/image.gallery.component";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumbs.component";
import VideoPlayer from "../../../components/video_player/video.player.component";
import Accordion from "../../../components/accordion/accordion.component";
import Tabs from "../../../components/tabs/tabs.component";
import Tab from "../../../components/tabs/tab.component";
import Button from "../../../components/button/button.component";

import commonStyles from "../common.module.scss";
import styles from "./theatre.module.scss";

import noPhoto from "../../../images/no_photo_man.png";
import noPosterPhoto from "../../../images/no_photo_spectacle.png";

import noTheatreImage1 from "../../../images/no_photo_theatre_type_1.jpg";
import noTheatreImage2 from "../../../images/no_photo_theatre_type_2.jpg";
import noTheatreImage3 from "../../../images/no_photo_theatre_type_3.jpg";
import SingleImageWithPreview from "../../../components/single_image_with_preview/single.image.with.preview";

function PublicTheatrePage() {
    let {id} = useParams();
    const navigate = useNavigate();
    const DOMPurify = createDOMPurify(window);

    const schoolStore = useSchoolStore();
    const {
        theatre,
        theatreActivity,
        theatreScore,
        loadTheatre,
        loadTheatreActivity,
        loadTheatreScore,
        loading
    } = useTheatresStore();
    const spectacleStore = useSpectaclesStore();

    React.useEffect(() => {
        const fetchData = async () => {
            if (id) {
                let tempTheatre = await loadTheatre({id});

                if (tempTheatre) {
                    await loadTheatreScore({id});
                    await loadTheatreActivity({
                        theatreID: id,
                    });
                    await schoolStore.loadSchool({id: tempTheatre.schoolID});
                    await spectacleStore.loadSpectaclesByTheatre({
                        theatreID: id,
                    });
                }
            }
        };

        fetchData();
    }, [id]);

    React.useEffect(() => {
        //console.log("Театр: ", theatre);
        //console.log("Активности: ", theatreActivity);
        //console.log("Постановки: ", spectacleStore.spectacles);
    }, [theatre, theatreActivity, spectacleStore.spectacles]);

    const getMainLogoImage = (item) => {
        const getRandomEmptyImage = () => {
            const number = Math.floor(Math.random() * (4 - 1) + 1);

            switch (number) {
                case 1:
                    return noTheatreImage1;
                case 2:
                    return noTheatreImage2;
                case 3:
                    return noTheatreImage3;
            }
        };

        let image = item.image;

        if (image === "" && item.photo.length > 0) image = item.photo[0].url;

        return image === "" ? getRandomEmptyImage() : process.env.REACT_APP_BASE_URL + image;
    };

    //Private components
    const TheatreInfo = () => {
        return (
            <div>
                <ul className={styles.list}>
                    <li className={styles.item}>
                        Год основания:
                        <span className={styles.accent}>
                                                {moment(theatre.foundation_date).format("YYYY")} г.
                                            </span>
                    </li>
                    <li className={styles.item}>
                        Возраст участников:
                        <span className={styles.accent}>
                                                {theatre.age_members.map((item) => item.age).join(", ")}
                                            </span>
                    </li>
                </ul>
                {
                    schoolStore.school.dir_fio !== ""
                    &&
                    <ul className={styles.peopleList}>
                        <li className={styles.card + " --hide"}>
                            <img className={styles.cardPhoto} src={noPhoto} alt={styles.cardTitle}/>
                            <h2 className={styles.cardTitle}>{schoolStore.school.dir_fio}</h2>
                            <p className={styles.cardSubtitle}>Руководитель</p>
                        </li>
                        {theatre.teachers.length > 0 &&
                            theatre.teachers.map((item) => (
                                <li key={item.fio} className={styles.card}>
                                    <SingleImageWithPreview image={item.photo} extraClass={styles.cardPhoto} noPhoto={noPhoto}/>
                                    <h2 className={styles.cardTitle}>
                                        {item.f} {item.i} {item.o}
                                    </h2>
                                    <p className={styles.cardSubtitle}>{item.position}</p>
                                </li>
                            ))}
                    </ul>
                }
                <div className={styles.socialBlock}>
                    {theatre.site && theatre.site !== "" && (
                        <a className={styles.link} href={theatre.site}>
                            Сайт театра <span className='mdi mdi-open-in-new'/>
                        </a>
                    )}
                    {theatre.social_links && theatre.social_links.length > 0 && (
                        <>
                            <h2 className={styles.link}>Социальные сети</h2>
                            <ul className={styles.links}>
                                {theatre.social_links.map((item) => (
                                    <li key={item}>
                                        <a
                                            className={styles.socialLink}
                                            href={item}
                                            target={"_blank"}
                                            rel='noopener nofollow noreferrer'
                                        >
                                            {window.global.getSocialIcon(item)}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                    {theatre.theatre_url_school && theatre.theatre_url_school !== "" && (
                        <a className={styles.link} href={theatre.theatre_url_school}
                           target={"_blank"}>
                            Сайт образовательной организации <span className='mdi mdi-open-in-new'/>
                        </a>
                    )}
                </div>
            </div>
        )
    }

    const TheatreScore = () => {
        if(!theatreScore || (parseInt(theatreScore.season_1_score) === 0 && parseInt(theatreScore.season_2_score) === 0))
            return null;

        return (
            <div>
                <h3 className={styles.subtitle}>Рейтинг</h3>
                <ul className={styles.list}>
                    {
                        theatreScore.season_1_score && parseInt(theatreScore.season_1_score) !== 0
                        &&
                        <li className={styles.item}>
                            1 сезон (2022-2023гг.):
                            <span className={styles.accent}>{theatreScore.season_1_score}</span>
                        </li>
                    }
                    {
                        theatreScore.season_2_score && parseInt(theatreScore.season_2_score) !== 0
                        &&
                        <li className={styles.item}>
                            2 сезон (2023-2024гг.):
                            <span className={styles.accent}>{theatreScore.season_2_score}</span>
                        </li>
                    }
                </ul>
            </div>
        )
    }

    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.5}}
        >
            <Breadcrumbs
                items={[
                    {
                        title: "Главная",
                        url: "/",
                    },
                    {
                        title: "Театры",
                        url: "/theatres",
                    },
                    {
                        title: theatre && Object.keys(theatre).length > 0 ? theatre.title : "Театр не найден",
                        url: "",
                    },
                ]}
            />
            {(loading || schoolStore.loading || spectacleStore.loading) ?
                <section className={commonStyles.section}>
                    <article className={commonStyles.wrap}>
                        <p className={commonStyles.title}>Загрузка...</p>
                    </article>
                </section>
                :
                <>
                    {id && !theatre && (
                        <section className={commonStyles.section}>
                            <article className={commonStyles.wrap}>
                                <p className={commonStyles.title}>Театр не найден</p>
                            </article>
                        </section>
                    )}
                    {id && theatre && Object.keys(theatre).length > 0 && (
                        <section className={[commonStyles.wrap, commonStyles.wrap_shadow].join(" ")}>
                            <div className={styles.twoColumns}>
                                <div className={styles.column}>
                                    <img className={styles.mainImage} src={getMainLogoImage(theatre)} alt={theatre.title}/>
                                </div>
                                <div className={styles.column}>
                                    <h1 className={styles.title}>{theatre.title}</h1>
                                    <p className={styles.subtitle}>{schoolStore.school.org_short_name}</p>
                                    <div className={styles.infoColumn}>
                                        <TheatreInfo/>
                                        <TheatreScore/>
                                    </div>

                                    {/*
                        <div
                            className={styles.description}
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                    theatre.director_message
                                ),
                            }}
                        />
                         */}
                                </div>
                            </div>
                            <Tabs theme='public' extraClass={styles.tabs}>
                                <Tab title={"О театре"}>
                                    {theatre.short_description.length > 20 && (
                                        <div
                                            className={styles.description}
                                            dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(theatre.short_description),
                                            }}
                                        />
                                    )}
                                    {theatre.short_description.length < 20 && (
                                        <p className={styles.subtitle}>Описание не добавлено</p>
                                    )}
                                    {/* бывшие педагоги
                            {theatre.teachers.length > 0 && (
                                <div className={styles.column}>
                                    <h2
                                        className={styles.title}
                                        style={{ textTransform: "unset" }}
                                    >
                                        Педагоги
                                    </h2>
                                    <ul className={styles.peopleList}>
                                        {theatre.teachers.map((item) => (
                                            <li
                                                key={item.fio}
                                                className={styles.card}
                                            >
                                                <img
                                                    className={styles.cardPhoto}
                                                    src={
                                                        item.photo
                                                            ? window.global
                                                                  .baseUrl +
                                                              item.photo
                                                            : noPhoto
                                                    }
                                                    alt={item.fio}
                                                />
                                                <h2
                                                    className={styles.cardTitle}
                                                    style={{
                                                        fontWeight: "400",
                                                        fontSize: "1em",
                                                    }}
                                                >
                                                    <span
                                                        className={
                                                            styles.cardTitleAccent
                                                        }
                                                    >
                                                        {item.f}
                                                    </span>
                                                    {item.i} {item.o}
                                                </h2>
                                                <ul className={styles.cardList}>
                                                    <li
                                                        className={
                                                            styles.cardItem
                                                        }
                                                    >
                                                        Должность:{" "}
                                                        <span
                                                            className={
                                                                styles.cardItemAccent
                                                            }
                                                        >
                                                            {item.position}
                                                        </span>
                                                    </li>
                                                    <li
                                                        className={
                                                            styles.cardItem
                                                        }
                                                    >
                                                        Стаж работы:{" "}
                                                        <span
                                                            className={
                                                                styles.cardItemAccent
                                                            }
                                                        >
                                                            {item.experience}
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div
                                                    className={
                                                        styles.cardDescription
                                                    }
                                                ></div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            */}
                                </Tab>
                                <Tab title={"Афиши спектаклей"}>
                                    {spectacleStore.theatreSpectacles.length > 0 ? (
                                        <ul className={styles.postersList}>
                                            {spectacleStore.theatreSpectacles.map((item, index) => (
                                                <li key={window.global.makeid(12)} className={styles.poster}>
                                                    <img
                                                        className={styles.posterImage}
                                                        src={
                                                            item.poster === null
                                                                ? noPosterPhoto
                                                                : process.env.REACT_APP_BASE_URL + item.poster
                                                        }
                                                        alt={item.title}
                                                    />
                                                    <div className={styles.posterMainText}>
                                                        <h3 className={styles.posterTitle}>{item.title}</h3>
                                                        {item.date && <p className={styles.posterDate}>{item.date}</p>}
                                                        <div
                                                            className={styles.posterDescription}
                                                            dangerouslySetInnerHTML={{
                                                                __html: DOMPurify.sanitize(
                                                                    window.global.truncateString(item.review, 160)
                                                                ),
                                                            }}
                                                        />
                                                        <p className={styles.posterInfo}></p>
                                                        <Button
                                                            extraClass={styles.posterButton}
                                                            type='button'
                                                            theme={"public_primary"}
                                                            text='Подробнее'
                                                            onClick={() => {
                                                                navigate("/spectacles/" + item.ID);
                                                            }}
                                                        />
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p className={styles.subtitle}>Постановки не добавлены</p>
                                    )}
                                </Tab>
                                <Tab title={"Фотогалерея"}>
                                    <ul className={styles.galleryList}>
                                        <ImageGallery extraClass={styles.galleryItem} items={theatre.photo}/>
                                    </ul>
                                    {theatre.photo.length === 0 && <p className={styles.subtitle}>Фото не добавлено</p>}
                                </Tab>
                                <Tab title={"Видеогалерея"}>
                                    {theatre.video_business_card && theatre.video_business_card !== "" && (
                                        <>
                                            <p className={styles.subtitle}>ВИДЕОВИЗИТКА ШКОЛЬНОГО ТЕАТРА</p>
                                            <br/>
                                            <ul className={styles.videoList}>
                                                <li className={styles.video}>
                                                    <VideoPlayer source={theatre.video_business_card}/>
                                                </li>
                                            </ul>
                                        </>
                                    )}
                                    {theatre.video.length > 0 && (
                                        <>
                                            <br/>
                                            <p className={styles.subtitle}>ВИДЕО ЛУЧШИХ ФРАГМЕНТОВ СПЕКТАКЛЕЙ</p>
                                            <br/>
                                            <ul className={styles.videoList}>
                                                {theatre.video.map((item, index) => (
                                                    <li key={index} className={styles.video}>
                                                        <VideoPlayer source={item}/>
                                                    </li>
                                                ))}
                                            </ul>
                                        </>
                                    )}
                                    {theatre.video_business_card === "" && theatre.video.length === 0 && (
                                        <p className={styles.subtitle}>Видео не добавлено</p>
                                    )}
                                </Tab>
                                <Tab title={"Активность театра"}>
                                    {theatreActivity &&
                                    (theatreActivity.events.length > 0 ||
                                        theatreActivity.own.length > 0 ||
                                        theatreActivity.visits.length > 0) ? (
                                        <>
                                            {theatreActivity.events.length > 0 && (
                                                <div className={styles.section}>
                                                    <h2
                                                        className={styles.title}
                                                        style={{
                                                            textTransform: "unset",
                                                            marginBottom: "1em",
                                                        }}
                                                    >
                                                        Посещение события
                                                    </h2>
                                                    {theatreActivity.events.map((item) => (
                                                        <Accordion key={item.ID} title={item.title}>
                                                            <ul className={styles.list}>
                                                                <li className={styles.item}>
                                                                    Тип события:
                                                                    <span className={styles.accent}>{item.eventType}</span>
                                                                </li>
                                                                <li className={styles.item}>
                                                                    Дата события:
                                                                    <span className={styles.accent}>
                                                                {moment(item.date).format("DD.MM.YYYY")}
                                                            </span>
                                                                </li>
                                                                <li className={styles.item}>
                                                                    Рецензия на событие:
                                                                    <span className={styles.accent}></span>
                                                                </li>
                                                            </ul>
                                                            <div
                                                                className={styles.description}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: DOMPurify.sanitize(item.review),
                                                                }}
                                                            />
                                                            <ul className={styles.galleryList}>
                                                                <ImageGallery
                                                                    extraClass={styles.galleryItem}
                                                                    items={item.photo}
                                                                />
                                                            </ul>
                                                        </Accordion>
                                                    ))}
                                                </div>
                                            )}
                                            {theatreActivity.visits.length > 0 && (
                                                <div className={styles.section}>
                                                    <h2
                                                        className={styles.title}
                                                        style={{
                                                            textTransform: "unset",
                                                            marginBottom: "1em",
                                                        }}
                                                    >
                                                        Участие в фестивалях, конкурсах
                                                    </h2>
                                                    {theatreActivity.visits.map((item) => (
                                                        <Accordion key={item.ID} title={item.title}>
                                                            <ul className={styles.list}>
                                                                <li className={styles.item}>
                                                                    Результативность участия:
                                                                    <span className={styles.accent}>{item.result}</span>
                                                                </li>
                                                                <li className={styles.item}>
                                                                    Дата мероприятия:
                                                                    <span className={styles.accent}>
                                                                {moment(item.date).format("DD.MM.YYYY")}
                                                            </span>
                                                                </li>
                                                                <li className={styles.item}>
                                                                    Описание мероприятия:
                                                                    <span className={styles.accent}/>
                                                                </li>
                                                            </ul>
                                                            <div
                                                                className={styles.description}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: DOMPurify.sanitize(item.review),
                                                                }}
                                                            />
                                                            <ul className={styles.galleryList}>
                                                                <ImageGallery
                                                                    extraClass={styles.galleryItem}
                                                                    items={item.photo}
                                                                />
                                                            </ul>
                                                        </Accordion>
                                                    ))}
                                                </div>
                                            )}
                                            {theatreActivity.own.length > 0 && (
                                                <div className={styles.section}>
                                                    <h2
                                                        className={styles.title}
                                                        style={{
                                                            textTransform: "unset",
                                                            marginBottom: "1em",
                                                        }}
                                                    >
                                                        Проведение собственного фестиваля в <br/>
                                                        образовательной организации
                                                    </h2>
                                                    {theatreActivity.own.map((item) => (
                                                        <Accordion key={item.ID} title={item.title}>
                                                            <ul className={styles.list}>
                                                                {item.file !== "" && (
                                                                    <li className={styles.item}>
                                                                        Положение о фестивале:{" "}
                                                                        <a
                                                                            className={styles.link}
                                                                            href={process.env.REACT_APP_BASE_URL + item.file}
                                                                            target={"_blank"}
                                                                        >
                                                                            Ссылка <span className='mdi mdi-open-in-new'/>
                                                                        </a>
                                                                    </li>
                                                                )}
                                                                <li className={styles.item}>
                                                                    Дата мероприятия:
                                                                    <span className={styles.accent}>
                                                                {moment(item.date).format("DD.MM.YYYY")}
                                                            </span>
                                                                </li>
                                                                <li className={styles.item}>
                                                                    Описание мероприятия:
                                                                    <span className={styles.accent}/>
                                                                </li>
                                                            </ul>
                                                            <div
                                                                className={styles.description}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: DOMPurify.sanitize(item.review),
                                                                }}
                                                            />
                                                            <ul
                                                                className={styles.galleryList}
                                                                style={{
                                                                    marginBottom: "min(2.75vmax, 1.875em)",
                                                                }}
                                                            >
                                                                <ImageGallery
                                                                    extraClass={styles.galleryItem}
                                                                    items={item.photo}
                                                                />
                                                            </ul>
                                                            <ul className={"gallery-form"}>
                                                                {item.video.map((item) => (
                                                                    <li key={item} className='gallery-form__item'>
                                                                        <VideoPlayer source={item.url}/>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </Accordion>
                                                    ))}
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <p className={styles.subtitle}>Активностей не добавлено</p>
                                    )}
                                </Tab>
                            </Tabs>
                        </section>
                    )}
                </>
            }
        </motion.div>
    );
}

export default PublicTheatrePage;
