import React from 'react';
import {useNavigate} from "react-router-dom";
import Icon from '@mdi/react';
import {mdiMicrosoftExcel} from '@mdi/js';
import {utils, writeFile} from 'xlsx';
import Swal from 'sweetalert2';

import useTheatresStore from "../../../store/admin/theatresStore";
import useAuthStore from "../../../store/authStore";

import Table from "../../../components/table/table.component";
import styles from "../../../components/search_filter/search.module.scss";
import Button from "../../../components/button/button.component";
import {isArray} from "lodash";

const TheatresPage = () => {
    const {user} = useAuthStore();

    const navigate = useNavigate();

    const {theatres, loadTheatres, loadTheatresForXLSX, loading} = useTheatresStore();

    const onItemClick = (props) => {
        navigate(`/admin/theatres/${props}`);
    };

    const fetchData = async () => {
        await loadTheatres({});
    };

    const exportToExcel = () => {
        const getColumnWidth = (data, column, title, minWidth = 50) => {
            const width = data.reduce((result, item) => {
                if (isArray(item[column]) || !item[column]) {
                    return result;
                }

                return Math.max(result, item[column].length);
            }, 5);

            if (isNaN(width))
                return Math.max(5, title.length);

            return Math.min(minWidth, Math.max(width, title.length));
        }

        Swal.fire({
            title: 'Пожалуйста подождите, отчет формируется...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
        });
        Swal.showLoading();

        setTimeout(async () => {
            const theatresXSLX = await loadTheatresForXLSX({});

            let theatresXSLX2 = [];

            theatresXSLX.map(item => {
                const form_activity = item.form_activity;
                const age_members = item.age_members;
                const social_links = item.social_links;
                const teachers = item.teachers;

                if (form_activity.length > 0) {
                    item.form_activity = form_activity[0].activity;
                }

                if (age_members.length > 0) {
                    item.age_members = age_members[0].age;
                }

                if (social_links.length > 0) {
                    item.social_links = social_links[0];
                }

                if (teachers.length > 0) {
                    item.teacherID = teachers[0].ID;
                    item.teacherFIO = teachers[0].fio;
                    item.teacherPosition = teachers[0].position;
                }

                delete item['teachers'];

                theatresXSLX2.push(item);

                for (let i = 1; i < Math.max(form_activity.length, age_members.length, social_links.length, teachers.length); i++) {
                    theatresXSLX2.push({
                        ID: "",
                        title: "",
                        schoolID: "",
                        school: "",
                        active: "",
                        link: "",
                        address: "",
                        coordinates: "",
                        form_activity: form_activity[i] ? form_activity[i].activity : "",
                        age_members: age_members[i] ? age_members[i].age : "",
                        foundation_date: "",
                        social_links: social_links[i] ? social_links[i] : "",
                        theatre_url_school: "",
                        teacherID: teachers[i] ? teachers[i].ID : "",
                        teacherFIO: teachers[i] ? teachers[i].fio : "",
                        teacherPosition: teachers[i] ? teachers[i].position : "",
                    });
                }
            });

            const workBook = utils.book_new();
            const workSheet = utils.json_to_sheet(theatresXSLX2);

            utils.sheet_add_aoa(workSheet, [[
                "ID театра",
                "Название театра",
                "ID школы",
                "Школа",
                "Статус",
                "Публичная страница",
                "Адрес",
                "Координаты",
                "Форма осуществления деятельности",
                "Возрастной состав участников школьного театра",
                "Дата основания",
                "Соцсети",
                "Страница театра на сайте образовательной организации",
                "ID педагога",
                "Фамилия, имя, отчество",
                "Должность",
            ]], {origin: 'A1'});
            utils.book_append_sheet(workBook, workSheet, 'Театры');

            workSheet["!cols"] = [
                {wch: getColumnWidth(theatresXSLX2, "ID", "ID театра")},
                {wch: getColumnWidth(theatresXSLX2, "title", "Название театра")},
                {wch: getColumnWidth(theatresXSLX2, "schoolID", "ID школы")},
                {wch: getColumnWidth(theatresXSLX2, "school_title", "Школа")},
                {wch: getColumnWidth(theatresXSLX2, "active", "Статус")},
                {wch: getColumnWidth(theatresXSLX2, "link", "Публичная страница")},
                {wch: getColumnWidth(theatresXSLX2, "address", "Адрес", 60)},
                {wch: getColumnWidth(theatresXSLX2, "coordinates", "Координаты")},
                {wch: getColumnWidth(theatresXSLX2, "form_activity", "Форма осуществления деятельности", 60)},
                {wch: getColumnWidth(theatresXSLX2, "age_members", "Возрастной состав участников школьного театра")},
                {wch: getColumnWidth(theatresXSLX2, "foundation_date", "Дата основания")},
                {wch: getColumnWidth(theatresXSLX2, "social_links", "Соцсети")},
                {wch: getColumnWidth(theatresXSLX2, "theatre_url_school", "Страница театра на сайте образовательной организации", 100)},
                {wch: getColumnWidth(theatresXSLX2, "teacherID", "ID педагога")},
                {wch: getColumnWidth(theatresXSLX2, "teacherFIO", "Фамилия, имя, отчество")},
                {wch: getColumnWidth(theatresXSLX2, "teacherPosition", "Должность")},
            ];

            writeFile(workBook, 'Театры.xlsx');

            Swal.close();
        }, 1500);
    };

    React.useEffect(() => {

        fetchData();

    }, []);

    const itemConfig = [
        {
            header: "ID",
            key: "ID",
            type: "int",
            filter: "number",
            sorting: true,
        },
        {
            header: "Название театра",
            key: "title",
            type: "string",
            filter: "string",
            sorting: true,
        },
        {
            header: "Школа",
            key: "school_title",
            type: "string",
            filter: "string",
            sorting: true,
        },
        {
            header: "Проверить баллы",
            key: "score_approved",
            type: "string",
            filter: "select",
            sorting: true,
        },
        {
            header: "Баллы за сезон 1",
            key: "score_season1",
            type: "int",
            filter: "number",
            sorting: true,
        },
        {
            header: "Баллы за сезон 2",
            key: "score_season2",
            type: "int",
            filter: "number",
            sorting: true,
        },
        {
            header: "Статус",
            key: "active",
            type: "string",
            filter: "select",
            sorting: true,
        },
        {
            header: "Дата обновления",
            key: "update_time",
            type: "datetime",
            filter: "date",
            sorting: true,
        },
    ];

    return (
        <Table
            title={"Таблица театров администратора" + user.ID}
            loading={loading}
            items={theatres}
            itemsConfig={itemConfig}
            onItemClick={onItemClick}
            withFilter={true}
        >
            <Button
                type="button"
                size="small"
                extraClass={styles.button}
                text={""}
                aria-label={"Выгрузка в XLS"}
                onClick={exportToExcel}
            >
                <Icon path={mdiMicrosoftExcel} size={1}/>
            </Button>
        </Table>
    );
};

export default TheatresPage;